import { z } from "zod";

export const chargepointDetailsSchema = z.object({
  chargePointIccid: z.string(),
  chargePointIdentifier: z.string(),
  chargePointBrand: z.string(),
  chargePointModel: z.string(),
  chargePointMaxPower: z.string(),
  teamType: z.string(),
  numConnectors: z.string(),
  connectors: z.array(
    z.object({
      connectorName: z.string(),
    })
  ),
  salesOrderNumber: z.number(),
});

export type ChargepointDetailsType = z.infer<typeof chargepointDetailsSchema>;
