import { SideCarousel } from "@Rolec-Services/rolec-ui";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type CarouselSectionProps = {
  images: string[];
  heading: ReactNode;
  description: ReactNode;
};

export function CarouselSection() {
  const { t } = useTranslation();

  const props: CarouselSectionProps = {
    images: ["/assets/monta-ad.png", "/assets/monta-ad.png"],
    heading: "Powered by Monta",
    description: t(
      "Onboard your charging station at Monta and you can start immediately. Monta gives you plenty of options to use your charging station the way you want. Determine your own rates and to whom which rate applies, or set free charging. It's all possible with Monta!"
    ),
  };

  return (
    <section className="w-full flex flex-col">
      <SideCarousel
        images={props.images}
        className="rounded-b-none lg:rounded-br-3xl "
      >
        <div className="flex flex-col gap-2">
          <h2 className="text-error-500 font-medium text-2xl">
            {props.heading}
          </h2>
          <p className="text-white text-sm">{props.description}</p>
        </div>
      </SideCarousel>
    </section>
  );
}
