import { Button } from "@Rolec-Services/rolec-ui";
import { useTranslation } from "react-i18next";

const languages = [
  // { code: "en", lang: "English" },
  { code: "nl", lang: "Dutch" },
  // { code: "de", lang: "German" },
  // { code: "dk", lang: "Danish" },
];

export function LanguageOptions() {
  const { i18n } = useTranslation();
  return (
    <div className="flex gap-4 justify-center">
      {languages.map(({ code, lang }) => (
        <Button
          key={code}
          variant="tertiary"
          className="btn btn-primary"
          onClick={() => i18n.changeLanguage(code)}
        >
          {lang}
        </Button>
      ))}
    </div>
  );
}
