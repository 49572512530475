import { TFunction } from "i18next";
import { z } from "zod";
import { addressDefaultValues, addressSchema } from "./address-schema";

export const siteSchema = (t: TFunction) => {
  return z.object({
    name: z
      .string()
      .min(2, t("Please enter a valid name"))
      .max(50, t("Please enter a valid name")),
    address: addressSchema(t),
  });
};

export type SiteType = z.infer<ReturnType<typeof siteSchema>>;

export const siteDefaultValues: SiteType = {
  name: "",
  address: addressDefaultValues,
};
