import {
  Button,
  Icon,
  Label,
  Separator,
  Switch,
  Tooltip,
} from "@Rolec-Services/rolec-ui";
import { useTranslation } from "react-i18next";
import { TextStack } from "~/components/atoms/text-stack";
import { ChargepointType } from "~/schemas/forms/chargepoint-schema";
import { kebabCaseToHuman } from "~/utils/format";

type ChargepointDetailsProps = {
  setSim: (value: boolean) => void;
  removeChargepoint: () => void;
} & ChargepointType;

export function ChargepointDetails({
  setSim,
  removeChargepoint,
  ...props
}: ChargepointDetailsProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <TextStack label={t("Serial Number")} value={props.serialNumber} />
        <Button
          size="icon-md"
          variant="destructive-secondary"
          onClick={removeChargepoint}
        >
          <Icon icon="Trash" />
        </Button>
      </div>
      <div className="flex justify-between">
        <TextStack label={t("Model")} value={kebabCaseToHuman(props.model)} />
        <TextStack label={t("Connectors")} value={props.connectors} />
        <TextStack label={t("Max Power")} value={props.maxPower} />
      </div>
      {props.hasSim && <SetSim addSim={props.addSim} setSim={setSim} />}

      <Separator />
    </div>
  );
}

type SetSimProps = {
  addSim: boolean;
  setSim: (value: boolean) => void;
};

function SetSim({ addSim, setSim }: SetSimProps) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-1 items-center">
        <Label>{t("Add Sim")}</Label>
        <Tooltip trigger={<Icon icon="HelpCircle" className="text-grey-300" />}>
          {t("Additional €8/month per sim")}
        </Tooltip>
      </div>
      <Switch checked={addSim} onCheckedChange={setSim} />
    </div>
  );
}
