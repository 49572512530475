import { Button, Dialog, Icon, Spinner } from "@Rolec-Services/rolec-ui";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type ChargepointActionsProps = {
  isLoading: boolean;
  addChargepoint: () => void;
  dialogDescription: ReactNode;
};

export function ChargepointActions({
  isLoading,
  addChargepoint,
  dialogDescription,
}: ChargepointActionsProps) {
  const { t } = useTranslation();
  return (
    <div className="flex w-full gap-4">
      <Button
        className="w-fit"
        variant="ev-green-secondary"
        onClick={addChargepoint}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <p>{t("Add")}</p>
            <Icon icon="Plus" />
          </>
        )}
      </Button>
      <Dialog
        description={dialogDescription}
        icon={{
          icon: "Search",
        }}
        title={t("Chargepoints")}
        trigger={
          <div className="bg-grey-50 py-2 px-3 rounded-lg hover:bg-grey-100 hover:cursor-pointer">
            {t("Summary")}
          </div>
        }
      />
    </div>
  );
}
