import { ScrollArea } from "@Rolec-Services/rolec-ui";
import { UseFormReturn } from "react-hook-form";
import { ChargepointType } from "~/schemas/forms/chargepoint-schema";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import { ChargepointDetails } from "./chargepoint-details";

export function ChargepointsSelected({
  chargepoints,
  removeChargepoint,
  setValue,
}: {
  chargepoints: ChargepointType[];
  removeChargepoint: (serialNumber: string) => void;
  setValue: UseFormReturn<OnboardingType>["setValue"];
}) {
  return (
    <>
      {!!chargepoints[0].serialNumber && (
        <ScrollArea>
          <div className="flex flex-col gap-4">
            {chargepoints.reverse().map((chargepoint, index) => (
              <ChargepointDetails
                key={`chargepoint-${index}`}
                {...chargepoint}
                removeChargepoint={() =>
                  removeChargepoint(chargepoint.serialNumber)
                }
                setSim={(value: boolean) =>
                  setValue(`chargepoint.${index}.addSim`, value)
                }
              />
            ))}
          </div>
        </ScrollArea>
      )}
    </>
  );
}
