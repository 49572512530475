import { Button, Spinner } from "@Rolec-Services/rolec-ui";
import { useTranslation } from "react-i18next";

export function End({ state, teamUrl }: { state: string; teamUrl: string }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-2 w-full h-full">
      <h1 className="text-2xl">{t("Onboarding")}</h1>
      <img src="/assets/monta-circle.svg" className="w-64" />
      <img src="/assets/powered-by-monta.svg" className="w-64" />
      {state === "submitted" && <Spinner className="size-24" />}
      {state === "successful" && (
        <div className="flex flex-col gap-6">
          <p>{t("Onboarding successful")}</p>
          <Button asChild>
            <a href={teamUrl}>{t("Monta Team Account")}</a>
          </Button>
        </div>
      )}
      {state === "failed" && (
        <p>{t("Onboarding failed - contact evsupport@eplucon.nl")}</p>
      )}
    </div>
  );
}
