export const enResources = {
  translation: {
    Onboarding: "Onboarding",
    Select: "Select",
    Add: "Add",
    Cancel: "Cancel",
    Next: "Next",
    Previous: "Previous",
    Submitted: "Submitted",
    Submit: "Submit",
    Confirm: "Confirm",
    Required: "Required",
    "See all": "See all",
    "Onboarding successful": "Onboarding successful",
    "Onboarding failed - contact evsupport@eplucon.nl":
      "Onboarding failed - contact evsupport@eplucon.nl",

    "Let's get started": "Let's get started",
    "Onboard your charger in just a few steps":
      "Onboard your charger in just a few steps",
    "Onboarding is now complete": "Onboarding is now complete",

    // Side carousel
    "Onboard your charging station at Monta and you can start immediately. Monta gives you plenty of options to use your charging station the way you want. Determine your own rates and to whom which rate applies, or set free charging. It's all possible with Monta!":
      "Onboard your charging station at Monta and you can start immediately. Monta gives you plenty of options to use your charging station the way you want. Determine your own rates and to whom which rate applies, or set free charging. It's all possible with Monta!",

    // Plan details
    "Unlimited Team Members": "Unlimited Team Members",
    "Monthly Cost": "Monthly Cost",
    "Transaction Fee": "Transaction Fee",
    "Tech Support": "Tech Support",
    "24/7 Support": "24/7 Support",
    Portal: "Portal",
    Dashboard: "Dashboard",
    "Transaction Overview": "Transaction Overview",
    "Smart Queue": "Smart Queue",
    "Load Balancing": "Load Balancing",
    "Advanced Features": "Advanced Features",
    "Pay with team wallet": "Pay with team wallet",
    "Team charge keys": "Team charge keys",
    "Pay by invoice": "Pay by invoice",
    "Sponsored chargepoints": "Sponsored chargepoints",
    Roaming: "Roaming",
    "Enterprise security": "Enterprise security",

    // Chargepoint
    Chargepoint: "Chargepoint",
    "Search Serial Number": "Search Serial Number",
    "Add Sim": "Add Sim",
    "Additional €8/month per sim": "Additional €8/month per sim",
    Chargepoints: "Chargepoints",
    Connectors: "Connectors",
    Summary: "Summary",
    "Serial Number": "Serial Number",
    Model: "Model",
    "Max Power": "Max Power",

    // Company
    Company: "Company",
    Name: "Name",
    "Optional (for companies)": "Optional (for companies)",
    "Search Address": "Search Address",
    Street: "Street",
    City: "City",
    "Zip Code": "Postcode",
    Country: "Country",

    // Guidance text
    "Something went wrong, please try again":
      "Something went wrong, please try again",
    "Please enter a valid serial number": "Please enter a valid serial number",
    "Please add a chargepoint": "Please add a chargepoint",
    "Please enter a valid street": "Please enter a valid street",
    "Please enter a valid city": "Please enter a valid city",
    "Please enter a valid zip code": "Please enter a valid postcode",
    "Please enter a valid name": "Please enter a valid name",
    "Please enter a valid telephone number":
      "Please enter a valid telephone number",
    "Please enter a valid email": "Please enter a valid email",

    // The rest
    "Team Name": "Team Name",
    "Team Owner": "Team Owner",
    "Owner Name": "Owner Name",
    "Owner Telephone": "Owner Telephone",
    "Owner Email": "Owner Email",
    "Team Finance": "Team Finance",
    "Finance Name": "Finance Name",
    "Finance Telephone": "Finance Telephone",
    "Finance Email": "Finance Email",
    "Team Installer": "Team Installer",
    "Installer Name": "Installer Name",
    "Installer Telephone": "Installer Telephone",
    "Installer Email": "Installer Email",
    "Installation Notes (optional)": "Installation Notes (optional)",
    Site: "Site",
    "Site Name": "Site Name",
  },
};
