import { Button, Icon } from "@Rolec-Services/rolec-ui";
import { useTranslation } from "react-i18next";
import { cn } from "~/utils/utils";
import { formSteps } from "../form-steps";

export type ActionsProps = {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
};

export function Actions({ step, nextStep, prevStep }: ActionsProps) {
  const { t } = useTranslation();
  return (
    <div
      className={cn("flex w-full justify-between", {
        "justify-center": step === 0,
      })}
    >
      {step > 0 && (
        <Button
          className="w-32"
          variant="text"
          onClick={prevStep}
          disabled={step === 0}
        >
          <Icon icon="ArrowLeft" />
          {t("Previous")}
        </Button>
      )}
      <Button
        variant={step == 0 ? "ev-green-primary" : "primary"}
        className="w-32"
        onClick={nextStep}
      >
        <ButtonContent step={step} />
      </Button>
    </div>
  );
}

function ButtonContent({ step }: { step: number }) {
  const { t } = useTranslation();
  switch (step) {
    case 0:
      return t("Start");
    case formSteps.length - 1:
      return t("Submit");
    default:
      return (
        <>
          {t("Confirm")}
          <Icon icon="ArrowRight" />
        </>
      );
  }
}
