import { SearchResultProps } from "~/components/molecules/search-bar";

export type AddressSearchState = {
  input: string;
  search: string;
  country: string;
  results: SearchResultProps[];
};

export type AddressSearchAction =
  | { type: "SET_INPUT"; payload: string }
  | { type: "SET_SEARCH"; payload: string }
  | { type: "SET_COUNTRY"; payload: string }
  | { type: "SET_RESULTS"; payload: SearchResultProps[] }
  | { type: "CLEAR_SEARCH" };

export const addressSearchInitialState: AddressSearchState = {
  input: "",
  search: "",
  country: "nl",
  results: [],
};

export function addressSearchReducer(
  state: AddressSearchState,
  action: AddressSearchAction
): AddressSearchState {
  switch (action.type) {
    case "SET_INPUT":
      return { ...state, input: action.payload };
    case "SET_SEARCH":
      return { ...state, search: action.payload };
    case "SET_COUNTRY":
      return { ...state, country: action.payload };
    case "SET_RESULTS":
      return { ...state, results: action.payload };
    case "CLEAR_SEARCH":
      return { ...state, input: "", search: "", results: [] };
    default:
      return state;
  }
}
