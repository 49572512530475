import { useTranslation } from "react-i18next";
import { LanguageOptions } from "../language-options";

export function Start() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full justify-center">
      <div className="flex flex-col items-center gap-2 w-full">
        <h1 className="text-2xl">{t("Let's get started")}</h1>
        <p>{t("Onboard your charger in just a few steps")}</p>
        <img src="/assets/monta-circle.svg" className="w-64" />
        <img src="/assets/powered-by-monta.svg" className="w-64" />
      </div>
      <LanguageOptions />
    </div>
  );
}
