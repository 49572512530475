import { PageWithCardLayout } from "./components/layout/page-with-card-layout";
import { CarouselSection } from "./components/molecules/carousel-section";
import { ContentResolver } from "./components/organisms/content-resolver";

export default function App() {
  return (
    <PageWithCardLayout>
      <ContentResolver />
      <CarouselSection />
    </PageWithCardLayout>
  );
}
