import { ReactNode } from "react";
import { cn } from "~/utils/utils";

export type ContentCardProps = {
  children: ReactNode;
  className?: string;
};

export function ContentCard({ children, className }: ContentCardProps) {
  return (
    <section
      className={cn(
        "w-full flex flex-col gap-14 lg:px-24 px-6 py-10 bg-white lg:rounded-bl-3xl hLg:rounded-l-3xl",
        className
      )}
    >
      <div className="flex w-full justify-between items-center">
        <img src="/assets/eplucon.svg" className="w-fit h-10" />
        <p className="text-sm font-semibold text-grey-300">Onboarding</p>
      </div>
      {children}
    </section>
  );
}
