import { Dropdown, FormInput } from "@Rolec-Services/rolec-ui";
import { useEffect, useReducer } from "react";
import { Control, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { countries } from "~/schemas/countries";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import {
  addressSearchInitialState,
  addressSearchReducer,
} from "~/utils/address-search";
import {
  fetchAutocomplete,
  formatAutocompleteData,
} from "~/utils/autocomplete";
import { debounce } from "~/utils/debounce";
import {
  fetchValidate,
  formatValidateData,
  updateAddressValues,
} from "~/utils/validate";
import { AddressFields } from "../address-fields";
import { SearchBar } from "../search-bar";

export function Site({
  control,
  setValue,
}: {
  control: Control<OnboardingType>;
  setValue: UseFormReturn<OnboardingType>["setValue"];
}) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(
    addressSearchReducer,
    addressSearchInitialState
  );

  const debouncedSearchInput = debounce((value: string) => {
    dispatch({ type: "SET_SEARCH", payload: value });
  }, 500);

  function validateSelectedResult(id: string, summaryline: string) {
    fetchValidate(id, summaryline, state.country)
      .then((data) => {
        updateAddressValues(
          "site",
          formatValidateData(data, summaryline),
          state.country,
          setValue
        );
      })
      .catch((error) => {
        console.error("Error validating data:", error);
      });
    dispatch({ type: "CLEAR_SEARCH" });
  }

  useEffect(() => {
    if (!state.search) {
      dispatch({ type: "SET_RESULTS", payload: [] });
      return;
    }
    fetchAutocomplete(state.search, state.country)
      .then((data) => {
        dispatch({
          type: "SET_RESULTS",
          payload: formatAutocompleteData(data),
        });
      })
      .catch(() => {
        dispatch({ type: "SET_RESULTS", payload: [] });
      });
  }, [state.search]);

  return (
    <>
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name="site.name"
        label={t("Site Name")}
      />
      <div className="flex w-full gap-2 justify-between items-center">
        <SearchBar
          value={state.input}
          onChange={(e) => {
            dispatch({ type: "SET_INPUT", payload: e.target.value });
            debouncedSearchInput(e.target.value);
          }}
          label={{ children: t("Search Address") }}
          searchResults={{
            searchResults: state.results,
            resultClick: validateSelectedResult,
          }}
        />
        <div className="pt-[18px]">
          <Dropdown
            value={state.country}
            onValueChange={(value) => {
              dispatch({ type: "SET_COUNTRY", payload: value });
            }}
            items={countries}
            placeholder="Country"
          />
        </div>
      </div>
      <AddressFields control={control} name="site" />
    </>
  );
}
