import { TFunction } from "i18next";
import { z } from "zod";
import { contactDefaultValues, contactSchema } from "./contact-schema";

export const teamSchema = (t: TFunction) => {
  return z.object({
    name: z
      .string()
      .min(2, t("Please enter a valid name"))
      .max(50, t("Please enter a valid name")),
    owner: contactSchema(t),
    finance: contactSchema(t),
  });
};

export type TeamType = z.infer<ReturnType<typeof teamSchema>>;

export const teamDefaultValues: TeamType = {
  name: "",
  owner: contactDefaultValues,
  finance: contactDefaultValues,
};
