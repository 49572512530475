import { Icon, ScrollArea } from "@Rolec-Services/rolec-ui";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export function PlanDetails() {
  const { t } = useTranslation();

  const rows = [
    {
      children: (
        <>
          <p>{t("Unlimited Team Members")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Monthly Cost")}</p>
          <p className="text-ev-500">€7,44 ex. / €9,00 incl.</p>
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Email support")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Transaction Fee")}</p>
          <p className="text-ev-500">Per sessie € 0,20 & +7.5%</p>
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Tech Support")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("24/7 Support")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Portal")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Dashboard")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Transaction Overview")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Smart Queue")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Load Balancing")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Advanced Features")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Pay with team wallet")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Team charge keys")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Pay by invoice")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Sponsored chargepoints")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Roaming")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
    {
      children: (
        <>
          <p>{t("Enterprise security")}</p>
          <Icon icon="Check" className="text-ev-500" />
        </>
      ),
    },
  ];
  return (
    <ScrollArea className="flex flex-col justify-start gap-2 w-full max-h-[580px] overflow-y-auto">
      {rows.map((row, index) => (
        <PlanRow key={index}>{row.children}</PlanRow>
      ))}
    </ScrollArea>
  );
}

function PlanRow({ children }: { children: ReactNode }) {
  return (
    <div className="flex w-full justify-between items-center border-b border-grey-100">
      <div className="flex w-full justify-between pl-2 pr-14 py-2">
        {children}
      </div>
    </div>
  );
}
