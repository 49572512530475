import { TFunction } from "i18next";
import { z } from "zod";

export const chargepointSchema = (t: TFunction) => {
  return z.object({
    iccidWithLuhn: z.string().min(2, t("Please enter a valid ICCID")),
    serialNumber: z
      .string()
      .min(2, t("Please enter a valid serial number"))
      .max(50, t("Please enter a valid serial number")),
    connectors: z
      .number()
      .min(1, t("Please enter a valid number of connectors")),
    model: z
      .string()
      .min(2, t("Please enter a valid model"))
      .max(50, t("Please enter a valid model")),
    maxPower: z.string().min(2, t("Please enter a valid power")),
    hasSim: z.boolean(),
    addSim: z.boolean(),
  });
};

export type ChargepointType = z.infer<ReturnType<typeof chargepointSchema>>;

export const chargepointDefaultValues: ChargepointType = {
  iccidWithLuhn: "",
  serialNumber: "",
  connectors: 0,
  model: "",
  maxPower: "",
  hasSim: false,
  addSim: false,
};
