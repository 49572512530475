import { Icon, IconPickerProps, ScrollArea } from "@Rolec-Services/rolec-ui";
import { ReactNode } from "react";

export type ContentFormStepProps = {
  icon: IconPickerProps["icon"];
  heading: ReactNode;
  children: ReactNode;
  numDots: number;
};

export function ContentFormStep({
  children,
  icon,
  heading,
  numDots,
}: ContentFormStepProps) {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <Icon icon={icon} className="size-8 text-ev-600" />
          {Array.from({ length: numDots }).map((_, index) => (
            <Icon
              key={index}
              icon="DotSolid"
              className="size-3 text-grey-200"
            />
          ))}
        </div>
        <h1 className="font-semibold text-3xl">{heading}</h1>
      </div>
      <ScrollArea className="lg:max-h-[510px] h-full pr-4 overflow-auto">
        <div className="flex flex-col gap-4">{children}</div>
      </ScrollArea>
    </div>
  );
}
