export const nlResources = {
  translation: {
    Onboarding: "Onboarding",
    Select: "Bevestig",
    Add: "Voeg toe",
    Cancel: "Annuleer",
    Next: "Volgende",
    Previous: "Vorige",
    "See all": "Zie alles",
    Submitted: "Verstuurd",
    Submit: "Verstuur",
    Confirm: "Bevestig",
    Required: "Verplicht",
    "Onboarding successful": "Onboarding succesvol",
    "Onboarding failed - contact evsupport@eplucon.nl":
      "Onboarding mislukt - contact evsupport@eplucon.nl",

    "Let's get started": "Laten we beginnen!",
    "Onboard your charger in just a few steps":
      "Onboard je laadpaal in een paar stappen",
    "Onboarding is now complete": "Onboarding is nu voltooid",

    // Side carousel
    "Onboard your charging station at Monta and you can start immediately. Monta gives you plenty of options to use your charging station the way you want. Determine your own rates and to whom which rate applies, or set free charging. It's all possible with Monta!":
      "​Onboard jouw laadpaal bij Monta en je kunt direct van start. Monta geeft je volop mogelijkheden om jouw laadpaal te gaan gebruiken zoals jij het wilt. Bepaal je eigen tarieven en voor wie welk tarief van toepassing is, of stel gratis laden in. Het kan allemaal met Monta!",

    // Plan details
    "Unlimited Team Members": "Onbeperkt in aantal teamleden",
    "Monthly Cost": "Maandelijkse kosten",
    "Transaction Fee": "Transactiekosten",
    "Tech Support": "Tech support (alleen voor beheerders / eigenaren)",
    "24/7 Support": "24/7 support",
    Portal: "Portaal",
    Dashboard: "Dashboard",
    "Transaction Overview": "Inzicht in transacties",
    "Smart Queue": "Slimme wachtrij",
    "Load Balancing": "Load Balancing",
    "Advanced Features": "Geavanceerde mogelijkheden",
    "Pay with team wallet": "Betalen met je team wallet",
    "Team charge keys": "Laaddruppels voor teams​",
    "Pay by invoice": "Betalen per factuur",
    "Sponsored chargepoints": "Gesponsorde EV-opladers​",
    Roaming: "Roaming",
    "Enterprise security": "Hoogwaardige veiligheid",

    // Chargepoint
    Chargepoint: "EV-oplader​",
    "Search Serial Number": "Zoek serienummer",
    "Add Sim": "Activeer de simkaart​",
    "Additional €8/month per sim": "Toeslag €3,- p/maand​",
    Chargepoints: "EV-opladers​",
    Connectors: "Connectoren",
    Summary: "Samenvatting",
    "Serial Number": "Serienummer",
    Model: "Model",
    "Max Power": "Maximaal vermogen",

    // Company
    Company: "Bedrijf",
    Name: "Naam",
    "Optional (for companies)": "Optioneel (voor bedrijven)",
    "Search Address": "Zoek adres",
    Street: "Straat",
    City: "Stad",
    "Zip Code": "Postcode",
    Country: "Land",

    // Guidance text
    "Something went wrong, please try again":
      "Er ging iets mis, probeer het nog eens​",
    "Please enter a valid serial number": "Voer een geldig serienummer in​",
    "Please add a chargepoint": "Voeg een EV-oplader toe​​",
    "Please enter a valid street": "Voer een geldige straat in",
    "Please enter a valid city": "Voer een geldige plaats in​",
    "Please enter a valid zip code": "Voer een gelde postcode in​​",
    "Please enter a valid name": "Voer een geldige naam in​",
    "Please enter a valid telephone number":
      "Voer een geldig telefoonnummer in",
    "Please enter a valid email": "Voer een geldig emailadres in​",

    // The rest
    "Team Name": "Team naam",
    "Team Owner": "Contactpersoon",
    "Owner Name": "Naam eigenaar",
    "Owner Telephone": "Telefoonnummer contactpersoon​",
    "Owner Email": "Emailadres contactpersoon​",
    "Team Finance": "Team administratie",
    "Finance Name": "Naam administratie",
    "Finance Telephone": "Telefoonnummer administratie",
    "Finance Email": "Emailadres administratie​",
    "Team Installer": "Installateur",
    "Installer Name": "Naam installateur",
    "Installer Telephone": "Telefoonnummer installateur",
    "Installer Email": "Emailadres installateur​",
    "Installation Notes (optional)": "Opmerkingen voor de monteur (optioneel)",
    Site: "Locatie EV-oplader​",
    "Site Name": "Locatie naam",
  },
};
