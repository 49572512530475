import {
  ScrollArea,
  SearchBox,
  SearchBoxProps,
} from "@Rolec-Services/rolec-ui";
import { ReactNode } from "react";

export type SearchBarProps = {
  searchResults?: SearchResultsProps;
} & SearchBoxProps;

export function SearchBar({ searchResults, ...props }: SearchBarProps) {
  return (
    <div className="relative w-full">
      <SearchBox {...props} />
      {!!searchResults?.searchResults.length && (
        <SearchResults {...searchResults} />
      )}
    </div>
  );
}

export type SearchResultsProps = {
  searchResults: SearchResultProps[];
  resultClick: (id: string, summaryline: string) => void;
};

export function SearchResults({
  searchResults,
  resultClick,
}: SearchResultsProps) {
  return (
    <div className="absolute rounded-md border border-grey-100 w-full overflow-y-auto mt-1 bg-white">
      <ScrollArea className={`h-[200px] overflow-y-auto`}>
        {searchResults.map((result) => (
          <SearchResult
            key={`search-result-${result.id}`}
            {...result}
            resultClick={resultClick}
          />
        ))}
      </ScrollArea>
    </div>
  );
}

export type SearchResultProps = {
  id: string;
  summaryline: string;
  label: ReactNode;
};

export function SearchResult({
  id,
  summaryline,
  label,
  resultClick,
}: SearchResultProps & {
  resultClick: (id: string, summaryline: string) => void;
}) {
  return (
    <div
      className="hover:cursor-pointer hover:bg-grey-50 hover:rounded-md px-4 py-2"
      onClick={() => resultClick(id, summaryline)}
    >
      {label}
    </div>
  );
}
