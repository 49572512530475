import { TFunction } from "i18next";
import { z } from "zod";

export const addressSchema = (t: TFunction) => {
  return z.object({
    street: z
      .string()
      .min(2, t("Please enter a valid street"))
      .max(50, t("Please enter a valid street")),
    city: z
      .string()
      .min(2, t("Please enter a valid city"))
      .max(50, t("Please enter a valid city")),
    zipCode: z.string().min(2, t("Please enter a valid zip code")),
    country: z.string().min(2, t("Please enter a valid country")),
  });
};

export type AddressType = z.infer<ReturnType<typeof addressSchema>>;

export const addressDefaultValues: AddressType = {
  street: "",
  city: "",
  zipCode: "",
  country: "",
};
