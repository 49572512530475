export const dkResources = {
  translation: {
    Onboarding: "Onboarding",
    Select: "Bekræft",
    Add: "Tilføj",
    Cancel: "Annuller",
    Next: "Næste",
    Previous: "Forrige",
    "See all": "Se alle",
    Submitted: "Indsendt",
    Submit: "Indsend",
    Confirm: "Bekræft",
    Required: "Påkrævet",
    "Onboarding successful": "Onboarding succesfuld",
    "Onboarding failed - contact evsupport@eplucon.nl":
      "Onboarding mislykkedes - kontakte evsupport@eplucon.nl",

    "Let's get started": "Lad os komme i gang!",
    "Onboard your charger in just a few steps": "Ombord din oplader på få trin",
    "Onboarding is now complete": "Onboarding er nu fuldført",

    // Side carousel
    "Onboard your charging station at Monta and you can start immediately. Monta gives you plenty of options to use your charging station the way you want. Determine your own rates and to whom which rate applies, or set free charging. It's all possible with Monta!":
      "An Bord Ihrer Ladestation in Monta können Sie sofort loslegen. Monta bietet Ihnen viele Möglichkeiten, Ihre Ladestation so zu nutzen, wie Sie es möchten. Bestimmen Sie Ihre eigenen Tarife und für wen dieser Tarif gilt, oder legen Sie eine kostenlose Abrechnung fest. Mit Monta ist alles möglich!",

    // Plan details
    "Unlimited Team Members": "Ubegrænsede teammedlemmer",
    "Monthly Cost": "Månedlige omkostninger",
    "Transaction Fee": "Transaktionsgebyr",
    "Tech Support": "Teknisk support (kun for administratorer / ejere)",
    "24/7 Support": "24/7 support",
    Portal: "Portal",
    Dashboard: "Dashboard",
    "Transaction Overview": "Transaktionsoversigt",
    "Smart Queue": "Smart kø",
    "Load Balancing": "Lastbalancering",
    "Advanced Features": "Avancerede funktioner",
    "Pay with team wallet": "Betal med team wallet",
    "Team charge keys": "Team opladningsnøgler",
    "Pay by invoice": "Betal med faktura",
    "Sponsored chargepoints": "Sponsorerede ladestationer",
    Roaming: "Roaming",
    "Enterprise security": "Virksomhedssikkerhed",

    // Chargepoint
    Chargepoint: "Ladepunkt",
    "Search Serial Number": "Søg serienummer",
    "Add Sim": "Tilføj Sim",
    "Additional €8/month per sim": "Yderligere €8/måned pr. sim",
    Chargepoints: "Ladepunkter",
    Connectors: "Forbindelser",
    Summary: "Resumé",
    "Serial Number": "Serienummer",
    Model: "Model",
    "Max Power": "Maksimal effekt",

    // Company
    Company: "Firma",
    Name: "Navn",
    "Optional (for companies)": "Valgfrit (for virksomheder)",
    "Search Address": "Søg adresse",
    Street: "Gade",
    City: "By",
    "Zip Code": "Postnummer",
    Country: "Land",

    // Guidance text
    "Something went wrong, please try again": "Noget gik galt, prøv igen",
    "Please enter a valid serial number": "Indtast et gyldigt serienummer",
    "Please add a chargepoint": "Tilføj et ladepunkt",
    "Please enter a valid street": "Indtast en gyldig gade",
    "Please enter a valid city": "Indtast en gyldig by",
    "Please enter a valid zip code": "Indtast en gyldig postnummer",
    "Please enter a valid name": "Indtast et gyldigt navn",
    "Please enter a valid email": "Indtast en gyldig e-mail",
    "Please enter a valid telephone number": "Indtast et gyldigt telefonnummer",

    // The rest
    "Team Name": "Team navn",
    "Team Owner": "Team ejer",
    "Owner Name": "Ejerens navn",
    "Owner Telephone": "Ejerens telefonnummer",
    "Owner Email": "Ejerens e-mail",
    "Team Finance": "Team økonomi",
    "Finance Name": "Økonomi navn",
    "Finance Telephone": "Økonomi telefonnummer",
    "Finance Email": "Økonomi e-mail",
    "Team Installer": "Team installatør",
    "Installer Name": "Installatørens navn",
    "Installer Telephone": "Installatørens telefonnummer",
    "Installer Email": "Installatørens e-mail",
    "Installation Notes (optional)": "Installationsnoter (valgfrit)",
    Site: "Sted",
    "Site Name": "Stednavn",
  },
};
