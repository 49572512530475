import { Control } from "react-hook-form";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import { ContactFields } from "../contact-fields";

export function Owner({ control }: { control: Control<OnboardingType> }) {
  return (
    <>
      <ContactFields
        name="team.owner"
        control={control}
        nameLabel="Owner Name"
        telephoneLabel="Owner Telephone"
        emailLabel="Owner Email"
      />
    </>
  );
}
