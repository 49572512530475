import { ReactNode } from "react";

export function TextStack({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-xs">{label}</p>
      <p>{value}</p>
    </div>
  );
}
