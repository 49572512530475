import { UseFormReturn } from "react-hook-form";
import { validateSchema, ValidateType } from "~/schemas/apis/validate-schema";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";

export function fetchValidate(
  id: string,
  summaryline: string,
  country: string
) {
  return fetch(
    `/api/location/validate?query=${summaryline}&country=${country}&id=${id}`
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error validating data:", error);
      throw error;
    });
}

export function formatValidateData(
  data: any,
  summaryline: string
): ValidateType {
  if (!Array.isArray(data) || data.length === 0) {
    throw new Error("No data found");
  }

  const parsedItem = validateSchema.parse(data[0]);
  if (!parsedItem) throw new Error("Error parsing data");

  parsedItem.street = summaryline;

  return parsedItem;
}

export function updateAddressValues(
  name: "company" | "site",
  data: ValidateType,
  country: string,
  setValue: UseFormReturn<OnboardingType>["setValue"]
) {
  setValue(`${name}.address.street`, data.street);
  setValue(`${name}.address.city`, data.posttown);
  setValue(`${name}.address.zipCode`, data.postcode);
  setValue(`${name}.address.country`, country);
}
