import { ReactNode } from "react";
import { cn } from "../../utils/utils";

export function PageWithCardLayout({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <div className="flex justify-center hLg:items-center h-screen w-screen bg-grey-50 md:bg-[url('/assets/windy-road.jpg')]">
      <div
        className={cn(
          "flex lg:flex-row flex-col lg:max-w-[1200px] lg:max-h-[833px]",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
