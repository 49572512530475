import { Control } from "react-hook-form";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import { ContactFields } from "../contact-fields";

export function Finance({ control }: { control: Control<OnboardingType> }) {
  return (
    <>
      <ContactFields
        name="team.finance"
        control={control}
        nameLabel="Finance Name"
        telephoneLabel="Finance Telephone"
        emailLabel="Finance Email"
      />
    </>
  );
}
