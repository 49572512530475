import { z } from "zod";
import { SearchResultProps } from "~/components/molecules/search-bar";
import {
  autocompleteSchema,
  AutocompleteType,
} from "~/schemas/apis/autocomplete-schema";

export function fetchAutocomplete(search: string, country: string) {
  return fetch(`/api/location/autocomplete?query=${search}&country=${country}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error fetching charge point details:", error);
      throw error;
    });
}

export function formatAutocompleteData(data: any): SearchResultProps[] {
  const parsedData = z.array(autocompleteSchema).parse(data);

  if (!parsedData) throw new Error("Error parsing data");

  return parsedData.map((item: AutocompleteType) => ({
    id: item.id,
    summaryline: item.summaryline,
    label: `${item.summaryline} ${item.locationsummary}`,
  }));
}
