import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { Layout } from "./components/layout/layout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <App />,
      },
    ],
  },

  {
    path: "*",
    element: <div>404 Not Found</div>,
  },
]);
