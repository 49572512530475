import { deResources } from "./deResources";
import { dkResources } from "./dkResources";
import { enResources } from "./enResources";
import { nlResources } from "./nlResources";

export const resources = {
  en: enResources,
  nl: nlResources,
  de: deResources,
  dk: dkResources,
};
