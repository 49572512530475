import { FormInput } from "@Rolec-Services/rolec-ui";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";

export function Team({ control }: { control: Control<OnboardingType> }) {
  const { t } = useTranslation();
  return (
    <>
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name="team.name"
        label={t("Team Name")}
      />
    </>
  );
}
