import { UseFormReturn } from "react-hook-form";
import { chargepointDetailsSchema } from "~/schemas/apis/chargepoint-details-schema";
import { ChargepointType } from "~/schemas/forms/chargepoint-schema";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";

export function initIndexState(): number {
  const savedFormData = sessionStorage.getItem("formData");
  if (!savedFormData) return 0;

  const formData = JSON.parse(savedFormData);

  if (!formData.chargepoint || !Array.isArray(formData.chargepoint)) return 0;

  if (!!formData.chargepoint[0].serialNumber)
    return formData.chargepoint.length;

  return 0;
}

export function initNumConnectorsState(): number {
  const savedFormData = sessionStorage.getItem("formData");
  if (!savedFormData) return 0;

  const formData = JSON.parse(savedFormData);
  if (!formData.chargepoint || !Array.isArray(formData.chargepoint)) return 0;

  const connectors = formData.chargepoint.reduce(
    (sum: number, chargepoint: ChargepointType) => {
      return sum + chargepoint.connectors;
    },
    0
  );

  return connectors;
}

export function isDuplicateChargepoint(
  chargepoints: ChargepointType[],
  serialNumber: string
) {
  return chargepoints.some(
    (chargepoint: ChargepointType) => chargepoint.serialNumber === serialNumber
  );
}

export function fetchChargepointDetails(serialNumber: string) {
  return fetch(`/api/chargepoint/details?serialnumber=${serialNumber}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error fetching charge point details:", error);
      throw error;
    });
}

export function formatChargepointData(data: unknown): ChargepointType {
  if (!data) throw new Error("No data found");

  const parsedItem = chargepointDetailsSchema.parse(data);
  if (!parsedItem) throw new Error("Error parsing data");

  return {
    iccidWithLuhn: parsedItem.chargePointIccid,
    serialNumber: parsedItem.chargePointIdentifier,
    model: parsedItem.chargePointModel,
    connectors: Number(parsedItem.numConnectors),
    maxPower: parsedItem.chargePointMaxPower,
    hasSim: !!parsedItem.chargePointIccid,
    addSim: false,
  };
}

export function updateChargepointValues(
  data: ChargepointType,
  index: number,
  setValue: UseFormReturn<OnboardingType>["setValue"]
) {
  setValue(`chargepoint.${index}.iccidWithLuhn`, data.iccidWithLuhn);
  setValue(`chargepoint.${index}.serialNumber`, data.serialNumber);
  setValue(`chargepoint.${index}.model`, data.model);
  setValue(`chargepoint.${index}.connectors`, data.connectors);
  setValue(`chargepoint.${index}.maxPower`, data.maxPower);
  setValue(`chargepoint.${index}.hasSim`, !!data.iccidWithLuhn);
}
