import { TFunction } from "i18next";
import { z } from "zod";
import {
  chargepointDefaultValues,
  chargepointSchema,
} from "./chargepoint-schema";
import { companyDefaultValues, companySchema } from "./company-schema";
import {
  installationDefaultValues,
  installationSchema,
} from "./installation-schema";
import { siteDefaultValues, siteSchema } from "./site-schema";
import { teamDefaultValues, teamSchema } from "./team-schema";

export const onboardingSchema = (t: TFunction) => {
  return z.object({
    chargepoint: z.array(chargepointSchema(t)),
    company: companySchema(t),
    team: teamSchema(t),
    site: siteSchema(t),
    installation: installationSchema(t),
  });
};

export type OnboardingType = z.infer<ReturnType<typeof onboardingSchema>>;

export const onboardingDefaultValues: OnboardingType = {
  chargepoint: [chargepointDefaultValues],
  company: companyDefaultValues,
  team: teamDefaultValues,
  site: siteDefaultValues,
  installation: installationDefaultValues,
};
