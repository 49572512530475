import { FormInput } from "@Rolec-Services/rolec-ui";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";

type ContactFieldsProps = {
  name: "team.owner" | "team.finance" | "installation.installer";
  control: Control<OnboardingType>;
  nameLabel: string;
  telephoneLabel: string;
  emailLabel: string;
};

export function ContactFields({
  name,
  control,
  nameLabel,
  telephoneLabel,
  emailLabel,
}: ContactFieldsProps) {
  const { t } = useTranslation();
  return (
    <>
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name={`${name}.name`}
        label={t(nameLabel)}
      />
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name={`${name}.telephone`}
        label={t(telephoneLabel)}
      />
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name={`${name}.email`}
        label={t(emailLabel)}
      />
    </>
  );
}
