import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import { ContentFormStep } from "../atoms/content-form-step";
import { formSteps } from "../form-steps";
import { Chargepoint } from "../molecules/steps/chargepoint/chargepoint";
import { Company } from "../molecules/steps/company";
import { End } from "../molecules/steps/end";
import { Finance } from "../molecules/steps/finance";
import { Installer } from "../molecules/steps/installer";
import { Owner } from "../molecules/steps/owner";
import { PlanDetails } from "../molecules/steps/plan-details";
import { Site } from "../molecules/steps/site";
import { Start } from "../molecules/steps/start";
import { Team } from "../molecules/steps/team";

type StepComponentProps = {
  form: UseFormReturn<OnboardingType>;
  state: string;
  teamUrl: string;
};

const stepComponents: Record<number, React.FC<StepComponentProps>> = {
  0: () => <Start />,
  1: () => <PlanDetails />,
  2: ({ form }) => (
    <Chargepoint
      setValue={form.setValue}
      reset={form.reset}
      getValues={form.getValues}
    />
  ),
  3: ({ form }) => <Company setValue={form.setValue} control={form.control} />,
  4: ({ form }) => <Team control={form.control} />,
  5: ({ form }) => <Owner control={form.control} />,
  6: ({ form }) => <Finance control={form.control} />,
  7: ({ form }) => <Installer control={form.control} />,
  8: ({ form }) => <Site setValue={form.setValue} control={form.control} />,
  9: ({ state, teamUrl }) => <End state={state} teamUrl={teamUrl} />,
};

export function RenderStep({
  step,
  ...props
}: { step: number } & StepComponentProps) {
  const { t } = useTranslation();
  const StepComponent = stepComponents[step];

  if (!StepComponent) return null;

  // these steps dont need the icon, heading etc
  if (step === 0 || step === 1 || step === 9) {
    return <StepComponent {...props} />;
  }

  return (
    <ContentFormStep
      icon={formSteps[step].icon}
      heading={t(formSteps[step].heading)}
      numDots={formSteps[step].numDots}
    >
      <StepComponent {...props} />
    </ContentFormStep>
  );
}
