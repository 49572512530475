import { SearchBox } from "@Rolec-Services/rolec-ui";
import { useTranslation } from "react-i18next";

type ChargepointSearchProps = {
  search: string;
  setSearch: (value: string) => void;
  error: boolean;
  guidanceMessage: string;
};

export function ChargepointSearch({
  search,
  setSearch,
  error,
  guidanceMessage,
}: ChargepointSearchProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-1">
      <SearchBox
        value={search}
        errorMessage={t("Required")}
        onChange={(e) => setSearch(e.target.value)}
        label={{ children: t("Search Serial Number") }}
        error={error}
        guidance={{ children: t(guidanceMessage) }}
        placeholder="DCK112233"
      />
    </div>
  );
}
