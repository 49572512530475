import { TFunction } from "i18next";
import { z } from "zod";

export const contactSchema = (t: TFunction) => {
  return z.object({
    name: z
      .string()
      .min(2, t("Please enter a valid name"))
      .max(50, t("Please enter a valid name")),
    telephone: z
      .string()
      .min(2, t("Please enter a valid telephone number"))
      .max(20, t("Please enter a valid telephone number"))
      .refine(
        (val) => /^\+?[0-9()-]+$/.test(val ?? ""),
        t("Please enter a valid telephone number")
      ),
    email: z.string().email(t("Please enter a valid email")),
  });
};

export type contactSchemaType = z.infer<ReturnType<typeof contactSchema>>;

export const contactDefaultValues: contactSchemaType = {
  name: "",
  telephone: "",
  email: "",
};
