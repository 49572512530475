import { z } from "zod";

export const validateSchema = z.object({
  addressline1: z.string(),
  addressline2: z.string(),
  addressline3: z.string(),
  summaryline: z.string(),
  subbuildingname: z.string(),
  buildingname: z.string(),
  premise: z.string(),
  street: z.string(),
  posttown: z.string(),
  county: z.string(),
  postcode: z.string(),
});

export type ValidateType = z.infer<typeof validateSchema>;
