export const deResources = {
  translation: {
    Onboarding: "Onboarding",
    Select: "Bestätigen",
    Add: "Hinzufügen",
    Cancel: "Abbrechen",
    Next: "Weiter",
    Previous: "Zurück",
    "See all": "Alle anzeigen",
    Submitted: "Eingereicht",
    Submit: "Einreichen",
    Confirm: "Bestätigen",
    Required: "Erforderlich",
    "Onboarding successful": "Onboarding erfolgreich",
    "Onboarding failed - contact evsupport@eplucon.nl":
      "Onboarding fehlgeschlagen - kontakt evsupport@eplucon.nl",

    "Let's get started": "Lass uns anfangen!",
    "Onboard your charger in just a few steps":
      "Laden Sie Ihr Ladegerät in wenigen Schritten an Bord",
    "Onboarding is now complete": "Onboarding ist jetzt abgeschlossen",

    // Side carousel
    "Onboard your charging station at Monta and you can start immediately. Monta gives you plenty of options to use your charging station the way you want. Determine your own rates and to whom which rate applies, or set free charging. It's all possible with Monta!":
      "An Bord Ihrer Ladestation in Monta können Sie sofort loslegen. Monta bietet Ihnen viele Möglichkeiten, Ihre Ladestation so zu nutzen, wie Sie es möchten. Bestimmen Sie Ihre eigenen Tarife und für wen dieser Tarif gilt, oder legen Sie eine kostenlose Abrechnung fest. Mit Monta ist alles möglich!",

    // Plan details
    "Unlimited Team Members": "Unbegrenzte Teammitglieder",
    "Monthly Cost": "Monatliche Kosten",
    "Transaction Fee": "Transaktionsgebühr",
    "Tech Support":
      "Technischer Support (nur für Administratoren / Eigentümer)",
    "24/7 Support": "24/7 Support",
    Portal: "Portal",
    Dashboard: "Dashboard",
    "Transaction Overview": "Transaktionsübersicht",
    "Smart Queue": "Intelligente Warteschlange",
    "Load Balancing": "Lastenausgleich",
    "Advanced Features": "Erweiterte Funktionen",
    "Pay with team wallet": "Mit Team-Wallet bezahlen",
    "Team charge keys": "Team-Ladekarten",
    "Pay by invoice": "Per Rechnung bezahlen",
    "Sponsored chargepoints": "Gesponserte Ladepunkte",
    Roaming: "Roaming",
    "Enterprise security": "Unternehmenssicherheit",

    // Chargepoint
    Chargepoint: "Ladepunkt",
    "Search Serial Number": "Seriennummer suchen",
    "Add Sim": "Sim Hinzufügen",
    "Additional €8/month per sim": "Zusätzlich €8/Monat pro Sim",
    Chargepoints: "Ladepunkte",
    Connectors: "Anschlüsse",
    Summary: "Zusammenfassung",
    "Serial Number": "Seriennummer",
    Model: "Modell",
    "Max Power": "Maximale Leistung",

    // Company
    Company: "Firma",
    Name: "Name",
    "Optional (for companies)": "Optional (für Unternehmen)",
    "Search Address": "Adresse suchen",
    Street: "Straße",
    City: "Stadt",
    "Zip Code": "Postleitzahl",
    Country: "Land",

    // Guidance text
    "Something went wrong, please try again":
      "Etwas ist schief gelaufen, bitte versuchen Sie es erneut",
    "Please enter a valid serial number":
      "Bitte geben Sie eine gültige Seriennummer ein",
    "Please add a chargepoint": "Bitte fügen Sie einen Ladepunkt hinzu",
    "Please enter a valid street": "Bitte geben Sie eine gültige Straße ein",
    "Please enter a valid city": "Bitte geben Sie eine gültige Stadt ein",
    "Please enter a valid zip code":
      "Bitte geben Sie eine gültige Postleitzahl ein",
    "Please enter a valid name": "Bitte geben Sie einen gültigen Namen ein",
    "Please enter a valid telephone number":
      "Bitte geben Sie eine gültige Telefonnummer ein",
    "Please enter a valid email":
      "Bitte geben Sie eine gültige E-Mail-Adresse ein",

    // The rest
    "Team Name": "Team-Name",
    "Team Owner": "Team-Eigentümer",
    "Owner Name": "Name des Eigentümers",
    "Owner Telephone": "Telefonnummer des Eigentümers",
    "Owner Email": "E-Mail des Eigentümers",
    "Team Finance": "Team-Finanzen",
    "Finance Name": "Name der Finanzen",
    "Finance Telephone": "Telefonnummer der Finanzen",
    "Finance Email": "E-Mail der Finanzen",
    "Team Installer": "Team-Installateur",
    "Installer Name": "Name des Installateurs",
    "Installer Telephone": "Telefonnummer des Installateurs",
    "Installer Email": "E-Mail des Installateurs",
    "Installation Notes (optional)": "Installationshinweise (optional)",
    Site: "Standort",
    "Site Name": "Standortname",
  },
};
