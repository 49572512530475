import { FormInput } from "@Rolec-Services/rolec-ui";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";

export function AddressFields({
  name,
  control,
}: {
  name: "company" | "site";
  control: Control<OnboardingType>;
}) {
  const { t } = useTranslation();
  return (
    <>
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name={`${name}.address.street`}
        label={t("Street")}
      />
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name={`${name}.address.city`}
        label={t("City")}
      />
      <FormInput
        errorMessage={t("Required")}
        control={control}
        name={`${name}.address.zipCode`}
        label={t("Zip Code")}
      />
    </>
  );
}
