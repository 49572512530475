import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@Rolec-Services/rolec-ui";
import { useEffect, useReducer } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  onboardingSchema,
  OnboardingType,
} from "~/schemas/forms/onboarding-schema";
import { formReducer, getDefaultValues, initialFormState } from "~/utils/data";
import { ContentCard } from "../atoms/content-card";
import { formSteps } from "../form-steps";
import { Actions } from "../molecules/actions";
import { RenderStep } from "./render-step";
import * as Sentry from "@sentry/react";

export function ContentResolver() {
  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const { t } = useTranslation();
  const schema = onboardingSchema(t);

  const form = useForm<OnboardingType>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    sessionStorage.setItem("step", formState.step.toString());
    sessionStorage.setItem("formData", JSON.stringify(form.getValues()));

    Sentry.setUser({
      email: form.getValues("team.owner.email"),
      geo: {
        country_code: form.getValues("company.address.country"),
        city: form.getValues("company.address.city"),
      },
      username: form.getValues("team.owner.name"),
    });
  }, [formState.step, form.watch()]);

  const processForm: SubmitHandler<OnboardingType> = async (data) => {
    dispatch({ type: "SET_STATE", payload: "submitted" });

    try {
      const res = await fetch("/api/onboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          provider: "MONTA",
          payload: data,
        }),
      });

      if (!res.ok) {
        dispatch({ type: "SET_STATE", payload: "failed" });
        return;
      }

      const responseData = await res.json();
      const teamUrl = responseData.team.url;

      dispatch({ type: "SET_STATE", payload: "successful" });
      dispatch({ type: "SET_TEAM_URL", payload: teamUrl });
      sessionStorage.removeItem("formData");
      sessionStorage.removeItem("step");
      form.reset();
    } catch (error) {
      console.error("Error:", error);
      dispatch({ type: "SET_STATE", payload: "failed" });
    }
  };

  type FieldName = keyof OnboardingType;

  async function nextStep() {
    const fields = formSteps[formState.step].fields;

    if (!!fields?.length) {
      const output = await form.trigger(fields as FieldName[], {
        shouldFocus: true,
      });

      if (!output) return;
    }

    // Custom validation for chargepoint array
    if (formState.step === 2) {
      const chargepoint = form.getValues("chargepoint");
      if (!chargepoint.length || chargepoint[0].iccidWithLuhn === "") {
        return;
      }
    }

    if (formState.step < formSteps.length) {
      if (formState.step === formSteps.length - 1) {
        await form.handleSubmit(processForm)();
        return;
      }

      form.clearErrors();
      dispatch({ type: "SET_STEP", payload: formState.step + 1 });
    }
  }

  function prevStep() {
    if (formState.step > 0) {
      dispatch({ type: "SET_STEP", payload: formState.step - 1 });
    }
  }

  return (
    <ContentCard>
      <div className="flex flex-col justify-between h-full gap-4">
        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(processForm)}
          >
            <RenderStep
              step={formState.step}
              state={formState.state}
              teamUrl={formState.teamUrl}
              form={form}
            />
          </form>
        </Form>
        {!formState.state || formState.state === "failed" ? (
          <Actions
            step={formState.step}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        ) : null}
      </div>
    </ContentCard>
  );
}
