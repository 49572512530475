import { TFunction } from "i18next";
import { z } from "zod";
import { contactDefaultValues, contactSchema } from "./contact-schema";

export const installationSchema = (t: TFunction) => {
  return z.object({
    installer: contactSchema(t),
    notes: z.string().optional(),
  });
};

export type InstallationType = z.infer<ReturnType<typeof installationSchema>>;

export const installationDefaultValues: InstallationType = {
  installer: contactDefaultValues,
  notes: "",
};
