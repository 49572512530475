import { FormInput } from "@Rolec-Services/rolec-ui";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import { ContactFields } from "../contact-fields";

export function Installer({ control }: { control: Control<OnboardingType> }) {
  const { t } = useTranslation();
  return (
    <>
      <ContactFields
        name="installation.installer"
        control={control}
      nameLabel="Installer Name"
        telephoneLabel="Installer Telephone"
        emailLabel="Installer Email"
      />

      <FormInput
        control={control}
        name="installation.notes"
        label={t("Installation Notes (optional)")}
      />
    </>
  );
}
