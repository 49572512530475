import { onboardingDefaultValues } from "~/schemas/forms/onboarding-schema";

export type FormState = {
  step: number;
  state: string;
  teamUrl: string;
};

export type FormAction =
  | { type: "SET_STEP"; payload: number }
  | { type: "SET_STATE"; payload: "submitted" | "failed" | "successful" }
  | { type: "SET_TEAM_URL"; payload: string };

export const initialFormState: FormState = {
  step: getStep(),
  state: "",
  teamUrl: "",
};

export function formReducer(state: FormState, action: FormAction) {
  switch (action.type) {
    case "SET_STEP":
      return { ...state, step: action.payload };
    case "SET_STATE":
      return { ...state, state: action.payload };
    case "SET_TEAM_URL":
      return { ...state, teamUrl: action.payload };
    default:
      return state;
  }
}

export function getDefaultValues() {
  const savedFormData = sessionStorage.getItem("formData");
  return savedFormData ? JSON.parse(savedFormData) : onboardingDefaultValues;
}

export function getStep() {
  const savedStep = sessionStorage.getItem("step");
  return savedStep ? parseInt(savedStep, 10) : 0;
}
