import { TFunction } from "i18next";
import { z } from "zod";
import { addressDefaultValues, addressSchema } from "./address-schema";

export const companySchema = (t: TFunction) => {
  return z.object({
    name: z.string().optional(),
    taxID: z.string().optional(),
    address: addressSchema(t),
  });
};

export type CompanySchema = z.infer<ReturnType<typeof companySchema>>;

export const companyDefaultValues: CompanySchema = {
  name: "",
  taxID: "",
  address: addressDefaultValues,
};
