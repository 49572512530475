import { IconPickerProps } from "@Rolec-Services/rolec-ui";

export type FormStepsProps = {
  id: string;
  icon: IconPickerProps["icon"];
  heading: string;
  numDots: number;
  fields?: string[];
};

export const formSteps: FormStepsProps[] = [
  {
    id: "Step 1",
    icon: "Charger",
    heading: "Start",
    numDots: 0,
  },
  {
    id: "Step 2",
    icon: "Charger",
    heading: "Plan Details",
    numDots: 0,
  },
  {
    id: "Step 3",
    icon: "Charger",
    heading: "Chargepoint",
    fields: [
      "chargepoint.serialNumber",
      "chargepoint.connectors",
      "chargepoint.model",
      "chargepoint.maxPower",
      "chargepoint.addSim",
    ],
    numDots: 7,
  },
  {
    id: "Step 4",
    icon: "Briefcase",
    heading: "Company",
    fields: [
      "company.name",
      "company.taxID",
      "company.address.street",
      "company.address.city",
      "company.address.zipCode",
      "company.address.country",
    ],
    numDots: 6,
  },
  {
    id: "Step 5",
    icon: "Users",
    heading: "Team",
    fields: ["team.name"],
    numDots: 5,
  },
  {
    id: "Step 6",
    icon: "User",
    heading: "Team Owner",
    fields: ["team.owner.name", "team.owner.telephone", "team.owner.email"],
    numDots: 4,
  },
  {
    id: "Step 7",
    icon: "Wallet",
    heading: "Team Finance",
    fields: [
      "team.finance.name",
      "team.finance.telephone",
      "team.finance.email",
    ],
    numDots: 3,
  },
  {
    id: "Step 8",
    icon: "Tool",
    heading: "Team Installer",
    fields: [
      "installation.installer.name",
      "installation.installer.telephone",
      "installation.installer.email",
      "installation.installer.installationNotes",
    ],
    numDots: 2,
  },
  {
    id: "Step 9",
    icon: "Home",
    heading: "Site",
    fields: [
      "site.name",
      "site.address.street",
      "site.address.city",
      "site.address.zipCode",
      "site.address.country",
    ],
    numDots: 1,
  },
  {
    id: "Step 10",
    icon: "Check",
    heading: "Complete",
    numDots: 0,
  },
];
