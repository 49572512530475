import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { chargepointDefaultValues } from "~/schemas/forms/chargepoint-schema";
import { OnboardingType } from "~/schemas/forms/onboarding-schema";
import {
  fetchChargepointDetails,
  formatChargepointData,
  initIndexState,
  initNumConnectorsState,
  isDuplicateChargepoint,
  updateChargepointValues,
} from "~/utils/chargepoint";
import { ChargepointActions } from "./chargepoint-actions";
import { ChargepointSearch } from "./chargepoint-search";
import { ChargepointsSelected } from "./chargepoints-selected";

type ChargepointProps = {
  setValue: UseFormReturn<OnboardingType>["setValue"];
  getValues: UseFormReturn<OnboardingType>["getValues"];
  reset: UseFormReturn<OnboardingType>["reset"];
};

export function Chargepoint({ setValue, getValues, reset }: ChargepointProps) {
  const { t } = useTranslation();
  const [serialSearch, setSerialSearch] = useState("");
  const [errorState, setErrorState] = useState({
    error: true,
    guidanceMessage: "Please add a chargepoint",
  });
  const [state, setState] = useState(() => ({
    numConnectors: initNumConnectorsState(),
    index: initIndexState(),
  }));
  const [isLoading, setIsLoading] = useState(false);

  function addChargepoint() {
    if (!serialSearch) {
      setErrorState({
        error: true,
        guidanceMessage: t("Please enter a valid serial number"),
      });
      return;
    }

    fetchChargepointDetails(serialSearch)
      .then((data) => {
        if (!data) {
          setErrorState({
            error: true,
            guidanceMessage: t("Serial number does not exist"),
          });
          setIsLoading(false);
          return;
        }

        if (
          isDuplicateChargepoint(
            getValues("chargepoint"),
            data.chargePointIdentifier
          )
        ) {
          setErrorState({
            error: true,
            guidanceMessage: t("Please enter a different serial number"),
          });
          setIsLoading(false);
          return;
        }

        const formattedData = formatChargepointData(data);

        if (!formattedData) {
          setErrorState({
            error: true,
            guidanceMessage: t("Something went wrong, please try again"),
          });
          setIsLoading(false);
          return;
        }

        updateChargepointValues(formattedData, state.index, setValue);
        incrementState(formattedData.connectors);
        setErrorState({ error: false, guidanceMessage: "" });
        setSerialSearch("");
        setIsLoading(false);
      })
      .catch(() => {
        setErrorState({
          error: true,
          guidanceMessage: t("Something went wrong, please try again"),
        });
        setIsLoading(false);
      });
  }

  function incrementState(numConnectors: number) {
    setState((prevState) => ({
      index: prevState.index + 1,
      numConnectors: prevState.numConnectors + Number(numConnectors),
    }));
  }

  function removeChargepoint(serialNumber: string) {
    const chargepoints = getValues("chargepoint");
    const removedChargepoint = chargepoints.find(
      (cp) => cp.serialNumber === serialNumber
    );

    if (!removedChargepoint) {
      return;
    }

    const updatedChargepoints = chargepoints.filter(
      (cp) => cp.serialNumber !== serialNumber
    );

    if (!updatedChargepoints.length) {
      reset({ chargepoint: [chargepointDefaultValues] });
      setState({ index: 0, numConnectors: 0 });
      return;
    }

    const numConnectorsToRemove = removedChargepoint.connectors;

    reset({ chargepoint: updatedChargepoints });
    setState((prevState) => ({
      index: prevState.index - 1,
      numConnectors: prevState.numConnectors - numConnectorsToRemove,
    }));
  }

  return (
    <>
      <ChargepointSearch
        search={serialSearch}
        setSearch={setSerialSearch}
        error={errorState.error}
        guidanceMessage={errorState.guidanceMessage}
      />
      <ChargepointActions
        isLoading={isLoading}
        addChargepoint={addChargepoint}
        dialogDescription={
          <>
            {state.index} {t("Chargepoints")}
            <br />
            {state.numConnectors} {t("Connectors")}
          </>
        }
      />
      <ChargepointsSelected
        chargepoints={getValues("chargepoint")}
        removeChargepoint={removeChargepoint}
        setValue={setValue}
      />
    </>
  );
}
