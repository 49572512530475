import { NetherlandsFlag } from "~/assets/flags/netherlands-flag";

export const countries = [
  // {
  //   value: "uk",
  //   children: <UnitedKingdomFlag />,
  // },
  // {
  //   value: "dk",
  //   children: <DenmarkFlag />,
  // },
  {
    value: "nl",
    children: <NetherlandsFlag />,
  },
  // {
  //   value: "de",
  //   children: <GermanyFlag />,
  // },
];
